<!-- addInfoCard -->
<!-- 添加学生信息 -->
<template>
    <div class="addInfo">
        <div v-wechat-title="$route.meta.title"></div>
        <div class="addInfo_content">
            <div class="addInfo_form">
                <van-form @submit="onSubmit" ref="form">
                    <div class="title">基础信息</div>
                    <van-field v-model="userInfo.idCard" :disabled="idCard_disabled" :error="false" required name="idCard"
                        label="身份证号" placeholder="请输入身份证号" :error-message="IDCARD_ERROR" :rules="idCardRules"
                        input-align="right" @blur="getIdCardFn(userInfo.idCard)" />
                    <van-field v-model="userInfo.studentName" :disabled="disabled" :error="false" required
                        name="studentName" label="姓名" placeholder="请输入姓名" input-align="right" :rules="nameRules"
                        :error-message="NAME_ERROR" class="custom-field" />
                    <van-field v-model="userInfo.mobile" :error="false" required name="mobile" label="电话"
                        placeholder="请输入联系电话" :error-message="MOBILE_ERROR" :rules="mobileRules" input-align="right" />
                    <div class="title"
                        style="margin-top: 15px;display:flex;align-items:center;justify-content:space-between">
                        <div>班级信息</div>
                        <div v-if="userInfo.semesterName">{{ userInfo.semesterName }}</div>
                    </div>
                    <van-field readonly clickable v-model="userInfo.schoolName" :error="false" name="schoolName"
                        label="学校名称" placeholder="请选择学校" input-align="right" :error-message="SCHOOLE_ERROR"
                        :rules="schooleRules" @click="schoolePopFn" />
                    <!-- <div class="err">请输入正确的学校名称，不得输入学校简称。正确的格式如 邯郸市第一中学、石家庄市第十七中学北校区。</div> -->
                    <van-field readonly :error="false" clickable name="gradeName" v-model="userInfo.gradeName" label="年级"
                        :placeholder="userInfo.gradeName ? userInfo.gradeName : '请选择'" @click="onGradFn" input-align="right"
                        :error-message="gradeName_ERROR" :rules="gradeNameRules" />
                    <van-field readonly :error="false" clickable name="className" v-model="userInfo.className" label="班级"
                        :placeholder="userInfo.className ? userInfo.className : '请选择'" @click="onClassFn"
                        input-align="right" :error-message="className_ERROR" :rules="classNameRules" />
                    <div class="submit_button">
                        <van-button round block type="info" native-type="submit">{{ buttonText
                        }}</van-button>
                    </div>
                </van-form>
            </div>
            <!-- 选择班级 -->
            <van-popup v-model="showPicker" position="bottom" :style="{ height: '80%' }" round class="class_choose"
                @closed="showPicker = false">
                <div class="title">
                    <div>选择班级</div>
                    <div style="color:#0CC777 ;">确定</div>
                </div>
                <div class="content" v-if="classList[0]">
                    <div v-for="(item, index) in classList" :key="index" :class="number == item.className ? 'classListActive' : 'classList'" @click="chooseClass(item.className)">
                        {{ item.className }}班
                    </div>
                </div>
                <div class="content" v-else>
                    <div v-show="item <= 24" :class="number == item ? 'classListActive' : 'classList'"
                        v-for="(item, index) in  48 " :key="index + 80" @click="chooseClass(item)">
                        {{ item }}班
                    </div>
                    <div class="more" @click="more">
                        <p class="moretext">更多班级</p>
                        <img v-show="showType" src="../assets/image/up.jpg" alt="">
                        <img v-show="!showType" src="../assets/image/down.jpg" alt="">
                    </div>
                    <div :ref="item == 20 ? 'scrollContainer' : ''" v-show="item > 24 && showType"
                        :class="number == item ? 'classListActive' : 'classList'" :id="item == 40 ? 'goid' : ''"
                        v-for="item in  152" :key="item / 3" @click=" chooseClass(item)">
                        {{ item }}班
                    </div>
                </div>
            </van-popup>
            <!-- 错误提示 -->
            <van-popup v-model="schoolShow" @close="schoolShow = false" class="schoolShow" round>
                <div class="title">学校名称输入错误！</div>
                <div>
                    您输入的学校名称“{{ userInfo.schoolName
                    }}”，格式不正确。请您输入学校完整的名称，不能输入简称，学校名称至少由6个字符组成，数字只能是大写的汉字，不能输入小写的阿拉伯数字，还需要带上相应的行政区域才符合要求。
                </div>
                <div style="color: #4CC44F;">正确的格式如：邯郸市第一中学，石家庄市第十七中学北校区，邯山区实验小学。</div>
                <div style="color: #ED3B3B ;">错误的格式如：邯郸一中，邯郸市第1中学，石家庄17中学，实验小学。</div>
                <div>(需要添加行政区域，如石家庄市新华实验小学才正确)</div>
                <div class="button" @click="schoolShow = false">关闭</div>
            </van-popup>
            <!-- 选择年级 -->
            <van-popup v-model="showPicker_NJ" position="bottom" :style="{ height: '80%' }" round class="class_choose"
                @closed="showPicker_NJ = false">
                <div class="title">
                    <div>选择年级</div>
                    <div style="color:#0CC777 ;">确定</div>
                </div>
                <div class="content" v-if="arr && arr.length > 0">
                    <div class="content_box" v-for="(item, index) in arr" :key="index">
                        <div class="box_title">{{ item.title }}</div>
                        <div style="display: flex; align-items: center;flex-wrap: wrap;">
                            <div :class="grade == itemm.title ? 'box_itemActive' : 'box_item'" style="font-size: 16px" v-for="itemm in item.item"
                                :key="itemm.title" @click="choosGrad(itemm)">{{
                                    itemm.title
                                }}{{ itemm.startYear ? '（' + itemm.startYear + '级）' : '' }}
                            </div>
                        </div>

                    </div>
                </div>
            </van-popup>
            <!-- 选择学校 -->
            <van-popup v-model="schoolePop" :close-on-click-overlay="false" position="bottom" round class="class_choose">
                <div class="choose_school">
                    <div class="choose_school_title">
                        <span>学校搜索</span>
                        <span @click="schoolePopClose">关闭</span>
                    </div>
                    <van-search v-model="schoolName" shape="round" placeholder="请输入搜索关键词" @input="schoolSearchFn" />
                </div>

                <div class="content_school">
                    <van-list v-model="schoolLoading" :offset="50" :finished="schoolFinished" :immediate-check="false"
                        finished-text="没有更多了" @load="onLoad">
                        <van-cell v-for="item in schoolList" :key="item.id" :title="item.schoolName"
                            :value="item.schoolTypeName" @click="schooleOnConfirm(item)" />
                    </van-list>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
import { getOpenId, getwxAccount } from "@/utils/token";
import { getQuerySchool, getAddStudent, getUpdateStudent, getqueryCurrentSemester, getQueryRelation, getGrade, getClass } from '@/api/api'
import { Toast, Dialog } from 'vant';
import LoginPageVue from '@/login/LoginPage.vue';
import { schoolTypeDict } from '@/utils/dictionaries'
export default {
    data() {
        return {
            classList: [],
            siteId: null,
            searchName: '',
            pageNum: 1,
            schoolFinished: false,
            schoolLoading: false,
            schoolName: '',
            schoolePop: false,
            activeNames: ['1'],
            openId: getOpenId(),
            schoolList: [],
            gradeList: [
                {
                    id: 1,
                    title: '幼儿园',
                    item: [
                        {
                            title: '小班',
                            code: 1,
                        },
                        {
                            title: '中班',
                            code: 2,
                        },
                        {
                            title: '大班',
                            code: 3,
                        }
                    ]
                },
                {
                    id: 2,
                    title: '小学',
                    item: [
                        {
                            title: '新一年级',
                            code: 10,
                        },
                        {
                            title: '一年级',
                            code: 11,
                        },
                        {
                            title: '二年级',
                            code: 12,
                        }, {
                            title: '三年级',
                            code: 13,
                        }, {
                            title: '四年级',
                            code: 14,
                        }, {
                            title: '五年级',
                            code: 15,

                        }, {
                            title: '六年级',
                            code: 16,

                        },
                    ]
                },
                {
                    id: 3,
                    title: '初中',
                    item: [
                        {
                            title: '新初一',
                            code: 20,
                        }, {
                            title: '初一',
                            code: 21,
                        },
                        {
                            title: '初二',
                            code: 22,
                        }, {
                            title: '初三',
                            code: 23,
                        }
                    ]
                }, {
                    id: 4,
                    title: '高中',
                    item: [
                        {
                            title: '新高一',
                            code: 30,
                        }, {
                            title: '高一',
                            code: 31,
                        },
                        {
                            title: '高二',
                            code: 32,
                        }, {
                            title: '高三',
                            code: 33,
                        }
                    ]
                }
            ],
            userInfo: {},
            number: null,
            showPicker: false,
            showPicker_NJ: false,
            showType: false,
            schoolShow: false,
            grade: '',
            schooleInfo: {},//查询获取的学校数据
            IDCARD_ERROR: '',//身份证错误提示
            NAME_ERROR: '',//姓名错误提示
            MOBILE_ERROR: '',//手机号错误提示
            SCHOOLE_ERROR: '',//学校名称错误提示
            gradeName_ERROR: '',//年级错误提示
            className_ERROR: '',//班级错误提示
            idCardRules: [
                { required: true, message: '请输入身份证号', trigger: 'blur' },
                { validator: this.validateIdCard, trigger: 'blur' }
            ],
            nameRules: [
                { required: true, message: '请输入姓名', trigger: 'blur' },
                { validator: this.validateName, trigger: 'blur' }
            ],
            mobileRules: [
                { required: true, message: '请输入联系电话', trigger: 'blur' },
                { validator: this.validateMobile, trigger: 'blur' }
            ],
            schooleRules: [
                { required: true, message: '请输入学校名称', trigger: 'blur' },
                { validator: this.validateSchoolName, trigger: 'blur' }
            ],
            gradeOffsetMap: {
                1: {
                    '小班': 1,
                    '中班': 2,
                    '大班': 3
                },
                2: {
                    '一年级': 0,
                    '二年级': 1,
                    '三年级': 2,
                    '四年级': 3,
                    '五年级': 4,
                    '六年级': 5
                },
                3: {
                    '初一': 0,
                    '初二': 1,
                    '初三': 2
                },
                4: {
                    '高一': 0,
                    '高二': 1,
                    '高三': 2
                }
            },
            gradeNameRules: [
                { required: true, message: '请选择年级', trigger: 'blur' },
            ],
            classNameRules: [
                { required: true, message: '请选择班级', trigger: 'blur' },
            ],
            gradeLevel: null,
            type: null,
            wxAccount: getwxAccount(),
            schoolEditionId: '',
            disabled: false,
            idCard_disabled: false,
            arr: [],
            buttonText: '添加学生',
            disabledGradeClass: true

        };
    },
    watch: {
        userInfo(newVal) {
            if (newVal) {
                this.userInfo = newVal;
                if (newVal.schoolName) {
                    this.schooleInfo.id = newVal.schoolId
                    this.userInfo.schoolName = newVal.schoolName
                    this.userInfo.gradeName = newVal.gradeName
                    this.userInfo.className = newVal.className
                    this.staryearFn()
                    this.getSchoolegrad(newVal.schoolType, newVal.configQuery)
                    this.disabledGradeClass = false
                    this.getqueryCurrentSemesterFn(decodeURIComponent(newVal.semesterName))
                }
                this.number = this.userInfo.className ? this.userInfo.className.split('班')[0] : ''
                this.grade = this.userInfo.gradeName
                this.staryearFn()
            }
        },
    },
    created() {
        let url = window.location.href
        let queryString = url.split('?')[1]
        const params = new URLSearchParams(queryString);
        if (params.size > 0) {
            this.siteId = params.get('siteId') ? params.get('siteId') : null
        }
    },
    methods: {
        // 关闭学校搜索弹窗
        schoolePopClose() {
            this.searchName = ''
            this.schoolFinished = true;
            this.pageNum = 1
            this.schoolePop = false
            this.schoolName = ''
            this.schoolList = []
        },
        // 搜索学校
        schoolSearchFn(val) {
            this.pageNum = 1
            this.schoolFinished = true;
            this.schoolList = []
            this.searchName = val
            let body = {
                "pageNum": this.pageNum,
                "pageSize": 10,
                "schoolName": this.searchName,
                "dutySiteId": this.siteId ? this.siteId : undefined
            }
            getQuerySchool(body).then(res => {
                if (res.code === 200) {
                    if (res.rows.length < 10) {
                        this.schoolFinished = true
                    } else {
                        this.schoolFinished = false
                    }
                    this.schoolLoading = false
                    this.schoolList = res.rows.map(item => {
                        let obj = schoolTypeDict.find(it => it.key === item.schoolType)
                        item.schoolTypeName = obj.value
                        return item
                    })
                } else {
                    this.schoolList = []
                }
            })
        },
        // 下滑加载
        onLoad() {
            // 异步更新数据
            this.schoolLoading = true
            this.pageNum = this.pageNum + 1
            let body = {
                "pageNum": this.pageNum,
                "pageSize": 10,
                "schoolName": this.searchName,
                "dutySiteId": this.siteId ? this.siteId : undefined
            }
            getQuerySchool(body).then(res => {
                if (res.code === 200) {
                    let arr = res.rows.map(item => {
                        let obj = schoolTypeDict.find(it => it.key === item.schoolType)
                        item.schoolTypeName = obj.value
                        return item
                    })
                    this.schoolList = this.schoolList.concat(arr)
                    this.schoolLoading = false
                    if (res.rows.length < 10) {
                        this.schoolFinished = true;
                    } else {
                        this.schoolFinished = false;
                    }
                } else {
                    this.schoolList = []
                }
            })
        },
        schoolePopFn() {
            this.schoolePop = true
            this.schoolName = this.userInfo.schoolName ? this.userInfo.schoolName : ''
            this.schoolSearchFn(this.schoolName)
        },
        getqueryCurrentSemesterFn() {
            getqueryCurrentSemester().then(res => {
                this.$set(this.userInfo, 'semesterName', res.data.semesterName);
                this.$set(this.userInfo, 'semesterId', res.data.id);
                if (res.data.semesterName.includes('上')) {
                    this.gradeList.forEach(val => {
                        if (val.id == 2) {
                            val.item = val.item.filter(it => it.title != "新一年级")
                        }
                        if (val.id == 3) {
                            val.item = val.item.filter(it => it.title != "新初一")
                        }
                        if (val.id == 4) {
                            val.item = val.item.filter(it => it.title != "新高一")
                        }
                    })
                }
                this.staryearFn()
            })
        },
        validateName(value, rule, callback) {
            if (!value) {
                this.NAME_ERROR = '请输入姓名';
                return false
            }
            if (value.length < 2) {
                this.NAME_ERROR = '姓名至少两个字符';
                return false

            }
            if (!/^[^\d]+[^\dA-Za-z]*$/.test(value)) {
                this.NAME_ERROR = '姓名不能为数字和英文';
                return false
            }
            this.NAME_ERROR = '';
            return true
        },
        validateIdCard(value, rule, callback) {
            if (!value) {
                this.IDCARD_ERROR = '请输入身份证号';
                return false
            }
            if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
                this.IDCARD_ERROR = '身份证号格式不正确';
                return false
            }
            let birthDateStr;
            if (value.length === 15) {
                birthDateStr = '19' + value.slice(6, 12);  // 15位身份证号，出生日期是第7到12位
            } else if (value.length === 18) {
                birthDateStr = value.slice(6, 14);  // 18位身份证号，出生日期是第7到14位
            }
            const birthDate = new Date(birthDateStr.slice(0, 4), birthDateStr.slice(4, 6) - 1, birthDateStr.slice(6, 8));
            const currentDate = new Date();
            let age = currentDate.getFullYear() - birthDate.getFullYear();
            const monthDiff = currentDate.getMonth() - birthDate.getMonth();
            const dayDiff = currentDate.getDate() - birthDate.getDate();
            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }
            if (age > 20) {
                this.IDCARD_ERROR = '年龄不得超过20岁';
                return false;
            }

            this.IDCARD_ERROR = '';
            return true
        },
        validateMobile(value, rule, callback) {
            if (!value) {
                this.MOBILE_ERROR = '请输入联系电话';
                return false
            }
            if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
                .test(value)) {
                this.MOBILE_ERROR = '电话号码格式不正确';
                return false

            }
            this.MOBILE_ERROR = '';
            return true
        },
        validateSchoolName(value, rule) {
            let regex = /^(.*省|.*市|.*区|.*县)(.{3,})(一|二|三|四|五|六|七|八|九|十|百|千|万|亿)*.{2,}$/;
            if (!value) {
                this.SCHOOLE_ERROR = '请输入学校名称';
                return false
            } else {
                this.SCHOOLE_ERROR == ''
                return true;
            }
            // if (!/^[\u4e00-\u9fa5]+$/.test(value)) {
            //     this.SCHOOLE_ERROR = '学校名称不能包含小写字母和阿拉伯数字';
            //     return false
            // }

        },
        // 提交
        onSubmit(e) {
            Toast.loading({
                message: '绑定学生中请稍等...',
                forbidClick: true,
                loadingType: 'spinner',
            });
            if (!this.userInfo.gradeCode) {
                this.gradeList.map(i => {
                    i.item.map(j => {
                        if (j.title == e.gradeName) {
                            this.userInfo.gradeCode = j.code
                            this.userInfo.enrollmentYear = j.startYear
                            return
                        }
                    })
                })
            }
            if (this.studentEditionId && this.buttonText != '绑定学生') {
                let obj = {
                    "studentName": this.userInfo.studentName,
                    "idCard": this.userInfo.idCard,
                    "mobile": this.userInfo.mobile,
                    "studentId": this.userInfo.studentId,
                    "studentEditionId": this.userInfo.studentEditionId,
                    "schoolId": this.userInfo.schoolId ? this.userInfo.schoolId : this.schooleInfo.id,
                    "schoolName": e.schoolName,
                    "gradeName": e.gradeName,
                    "className": e.className,
                    "gradeCode": this.userInfo.gradeCode,
                    "enrollmentYear": this.userInfo.enrollmentYear,
                    "semesterName": this.userInfo.semesterName,
                    "semesterId": this.userInfo.semesterId,
                    "openId": this.openId,
                    "wxAccount": this.wxAccount
                }
                getUpdateStudent(obj).then(res => {
                    if (res.code == 200) {
                        if (this.type == '修改') {
                            Toast('修改成功')
                        } else {
                            Toast('添加成功')
                        }
                        this.$emit('update:success', this.userInfo.studentId);
                        this.userInfo = {}
                    } else {
                        Toast('请联系管理员', res.msg)
                    }
                }).catch(err => {
                    console.log("err,err", err);
                })
            } else {
                let obj = {
                    "studentName": this.userInfo.studentName,
                    "idCard": this.userInfo.idCard,
                    "mobile": this.userInfo.mobile,
                    // "schoolEditionId": this.schooleInfo.schoolEditionId,
                    "schoolId": this.userInfo.schoolId ? this.userInfo.schoolId : this.schooleInfo.id,
                    "schoolName": this.userInfo.schoolName,
                    "gradeName": this.userInfo.gradeName,
                    "className": this.userInfo.className,
                    "gradeCode": this.userInfo.gradeCode,
                    "enrollmentYear": this.userInfo.enrollmentYear,
                    "semesterName": this.userInfo.semesterName,
                    "semesterId": this.userInfo.semesterId,
                    "openId": this.openId,
                    "wxAccount": this.wxAccount
                }
                getAddStudent(obj).then(res => {
                    if (res.code == 200) {
                        if (this.type == '修改') {
                            Toast('修改成功')
                        } else {
                            Toast('添加成功')
                        }
                        this.$emit('update:success', this.userInfo.studentId, 'add');
                        this.userInfo = {}
                    } else {
                        Toast('请联系管理员', res.msg)
                    }
                }).catch(err => {
                    console.log("err,err", err);
                })
            }
        },
        // 验证身份证是否存在
        getIdCardFn(e) {
            if (!e) {
                return
            }
            if (!this.validateIdCard(e)) {
                return
            }
            getQueryRelation({ idCard: e }).then(res => {
                if (res.data != 1) {
                    Dialog.confirm({
                        message: '查询到该身份证号已有学生信息，是否同步信息',
                    })
                        .then(() => {
                            this.userInfo = res.data
                            this.disabled = true
                            this.buttonText = '绑定学生'
                            if (!this.userInfo.semesterName) {
                                this.getqueryCurrentSemesterFn()
                            }
                        })
                        .catch(() => {
                            this.userInfo.idCard = ''
                            // on cancel
                        });

                }
            })
        },
        // 选择 年级
        onGradFn() {
            if (!this.disabledGradeClass) {
                this.showPicker_NJ = true
            }
        },
        onClassFn() {
            if (!this.disabledGradeClass) {
                this.showPicker = true
            }
        },
        // 选择班级
        chooseClass(item) {
            this.userInfo.className = item + '班'
            this.number = item
            this.showPicker = false
        },
        choosGrad(item) {
            this.userInfo.enrollmentYear = item.startYear
            this.userInfo.gradeName = item.title
            this.userInfo.gradeCode = item.code
            this.grade = item.title
            this.showPicker_NJ = false
            this.showPicker = true
        },
        getQuerySchoolFn(schoolName) {
            let body = {
                "schoolName": schoolName
            }
            getQuerySchool(body).then(res => {
                this.schooleInfo = res.rows[0]
                this.staryearFn()
                this.getSchoolegrad(this.schooleInfo.schoolType, this.schooleInfo.configQuery)
                // if (res.data.length > 0 && res.data.length == 1) {

                // } else if (res.data.length == 0) {
                //     let value = this.userInfo.schoolName.trim();
                //     let regex = /^(.*省|.*市|.*区|.*县).{3,}(一|二|三|四|五|六|七|八|九|十|百|千|万|亿)*.{1,}$/;
                //     if (!value) {
                //         this.schoolShow = true
                //         return false
                //     }
                //     if (!regex.test(value)) {
                //         this.schoolShow = true
                //         return false
                //     }
                //     this.schooleInfo.schoolName = this.userInfo.schoolName
                //     this.disabledGradeClass = false
                //     this.getSchoolegrad(this.schooleInfo.schoolType)
                // } else {
                //     this.schoolList = res.rows
                //     this.schooleShow = true
                // }
            })
        },
        getSchoolegrad(schoolType, configQuery) {
            if (configQuery) {
                getGrade({ schoolName: this.userInfo.schoolName }).then(res => {
                    this.arr = [
                        {
                            id: -1,
                            title: '',
                            item: res.data.map(item => ({ title: item.gradeName, code: item.gradeCode }))
                        }
                    ]
                })
                getClass({ schoolName: this.userInfo.schoolName }).then(res => {
                    this.classList = res.data.map(item => ({ className: item.className.substring(0, item.className.length - 1) }))
                })
            } else {
                let arr = []
                this.classList = []
                if (schoolType == 5) {
                    this.gradeList.map(i => {
                        if (i.id == 2 || i.id == 3) {
                            arr.push(i)
                        }
                    });
                    console.log('arr==>5', arr);
                } else if (schoolType == 6) {
                    this.gradeList.map(i => {
                        if (i.id == 3 || i.id == 4) {
                            arr.push(i)
                        }
                    });
                    console.log('arr==>6', arr);
                } else if (schoolType == 8) {
                    arr = this.gradeList;
                    console.log('arr==>8', arr);
                } else {
                    this.gradeList.map(i => {
                        if (i.id == schoolType) {
                            console.log("i.id == schoolType", i.id, schoolType);
                            arr.push(i)
                        }
                    });
                    if (arr.length == 0) {
                        arr = this.gradeList;
                        console.log("length==0==>arr", arr);
                    }
                }
                // this.gradeList = arr;
                this.arr = arr
            }
        },
        schooleOnConfirm(e) {
            if (e.schoolName) {
                this.schooleInfo = e
                this.userInfo.schoolName = e.schoolName
                this.userInfo.gradeName = ''
                this.userInfo.className = ''
                this.userInfo.schoolId = e.id
                this.staryearFn()
                this.getSchoolegrad(e.schoolType, e.configQuery)
                this.disabledGradeClass = false
                this.schoolePopClose()
            } else {
                this.userInfo.schoolName = ''
            }
        },
        staryearFn() {
            const currentYear = this.userInfo.semesterName ? parseInt(this.userInfo.semesterName.split('-')[0]) : ''
            this.gradeList.forEach((i) => {
                if (i.id != 1) {
                    i.item.forEach((j, index) => {
                        if (currentYear) {
                            j.startYear = currentYear - index
                        } else {
                            j.startYear = ''
                        }
                    })
                }
            })
            // return this.gradeList
        },
        more() {
            if (this.showType) {
                this.showType = false
            } else {
                this.showType = true
            }
            setTimeout(() => {
                this.$el.querySelector('#goid').scrollIntoView({ block: "start", behavior: "smooth" });
            }, 0);
        },

    }
}
</script>

<style lang="less" scoped>
.content_box {
    width: 100%;
    .box_title {
        font-family: PingFangSC-Medium;
        font-size: 17px;
        color: #333333;
        font-weight: 600;
        margin: 10px 0;
    }

    .box_itemActive {
        width: 155px;
        padding: 13px 5px;
        background: #0CC777;
        border-radius: 5px;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #FFF;
        text-align: center;
        font-weight: 400;
        margin-bottom: 8px;
        margin-right: 7px;
    }

    .box_item {
        width: 152px;
        padding: 13px 5px;
        border: 0.5px solid lightgrey;
        background: #FFFFFF;
        border-radius: 5px;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #333333;
        text-align: center;
        font-weight: 400;
        margin-bottom: 8px;
        margin-right: 7px;
    }
}

.addInfo {
    background: #f7f7f7;
}


.title {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 15px;
}

.addInfo_form {
    // background: #FFFFFF;
    border-radius: 5px;
    // padding: 14px 14px 0px 14px;

    /deep/.van-field__error-message {
        text-align: right !important;
    }

    /deep/ .custom-field .van-field__control {
        color: #333333;
    }

    /deep/.van-field__label {
        font-weight: 600;
        font-size: 16px;
        margin-right: 0px;
    }

    /deep/.van-field__control--right {
        font-weight: 500;
        font-size: 16px;
    }

    /deep/.van-cell--required::before {
        position: absolute;
        left: 0.21333rem;
        color: #ee0a24;
        top: 0.32rem;
        font-size: 0.37333rem;
        content: '*';
    }

}

.submit_button {
    background: #fff;
    padding-bottom: 10px;
    padding-top: 10px;

    .van-button {
        width: 92%;
        margin: auto;
        background: #0CC777;
        border-radius: 5px;
        border: none;
    }
}

.err {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #FF3333;
    text-align: justify;
    line-height: 16px;
    font-weight: 400;
    padding: .26667rem .42667rem;
    background: #fff;
}

.class_choose {
    height: 50%;

    .title {
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        font-family: PingFangSC-Medium;
        font-size: 17px;
        color: #333333;
        font-weight: 600;
        position: fixed;
        left: 0;
        right: 0;
    }

    .choose_school {
        position: fixed;
        left: 0;
        right: 0;
        z-index: 1;

        .choose_school_title {
            border-radius: 22px 22px 0 0;
            background: #FFF;
            border-bottom: 1px #F4F5F5 solid;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            font-size: 17px;
            color: #333333;
            font-weight: 600;
        }
    }

    .content {
        margin-top: 50px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .classList {
        width: 80px;
        height: 51px;
        background: #FFFFFF;
        border-radius: 5px;
        text-align: center;
        line-height: 51px;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 9px;
    }

    .classListActive {
        width: 80px;
        height: 51px;
        background: #0CC777;
        border-radius: 5px;
        text-align: center;
        line-height: 51px;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #FFF;
        font-weight: 600;
        margin-bottom: 9px;
    }

    .more {
        margin: 16px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .moretext {
            font-family: PingFangSC-Regular;
            font-size: 17px;
            color: #333333;
            text-align: center;
            font-weight: 400;
        }

        img {
            width: 15px;
            height: 10px;
            margin-left: 10px;
        }
    }
}

.schoolShow {
    width: 80%;
    padding: 15px;

    .title {
        font-family: PingFangSC-Semibold;
        font-size: 15px;
        color: #333333;
        text-align: center;
        font-weight: 600;
    }

    div {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .button {
        padding: 10px 0;
        background: #0CC777;
        border-radius: 5px;
        margin: auto;
        text-align: center;
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #FFFFFF;
        font-weight: 600;
    }

}

.content_school {
    padding: 0 15px 15px 15px 15px;
    margin-top: 100px;

    /deep/.van-cell {
        justify-content: space-between;
    }

    /deep/.van-cell__title,
    .van-cell__value {
        flex: none;
        -webkit-flex: none;
    }

    /deep/.van-cell__value {
        color: #0CC777;
    }
}

.schoolContent {
    width: 100%;
}
</style>