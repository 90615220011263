/////////////////////永久存储////////////////////////
export const setForLocal = (key, value) => {
  localStorage.setItem(key, value);
};
export const getForLocal = (key) => {
  return localStorage.getItem(key);
};
export const removeForLocal = (key) => {
  localStorage.removeItem(key);
};
export const clearLocal = () => {
  localStorage.clear();
};
/////////////////////临时存储////////////////////////
export const setForSession = (key, value) => {
  if (!value) return;
  sessionStorage.setItem(key, value);
};
export const getForSession = (key) => {
  return sessionStorage.getItem(key);
};
export const removeForSession = (key) => {
  sessionStorage.removeItem(key);
};
export const clearSession = () => {
  sessionStorage.clear();
};
export const maskIdNumber = (id) => {
  if (id) {
    let card = id.replace(/(\d{6})\d{8}(\d{2})/, "$1********$2");
    return card
  }

};
export const getSex = (data) => {
  // 指定日期
  const birthday = new Date(data);
  // 当前日期
  const today = new Date();
  // 计算年龄
  let age = today.getFullYear() - birthday.getFullYear();
  // 检查是否已经过生日
  if (today.getMonth() < birthday.getMonth() || (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age
};
export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}