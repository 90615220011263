import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/token";
import resultCode from "@/utils/result-code";
import { Toast } from "vant";

NProgress.configure({
  easing: "ease", // 动画方式，和css动画属性一样（默认：ease）
  speed: 500, // 递增进度条的速度，单位ms（默认： 200）
  showSpinner: false, // 是否显示加载ico
  trickle: true, //是否自动递增
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比，0-1（默认：0.08）
});

const ajax = axios.create({
  baseURL: "/prod-api",
  // baseURL: "https://slsc.ayoh.com.cn/prod-api",
  // baseURL: "http://199.168.100.13:8025",
});

// 添加请求拦截器
ajax.interceptors.request.use(
  function (config) {
    //请求开始时显示进度条
    NProgress.start();
    config.headers = {
      Authorization: "Bearer " + getToken(),
    };
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
ajax.interceptors.response.use(
  function (response) {
    //服务响应时完成进度条
    NProgress.done();
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.code !== resultCode.SUCCESS) {
      if (response.data.code === resultCode.FORBIDDEN) {
        // if (!(document.getElementsByClassName("van-toast__text").length > 0)) {
        //   Toast("令牌失效，即将重新登录");
        //   sessionStorage.removeItem("token");
        //   setTimeout(() => {
        //     window.location.href = location.origin;
        //   }, 2000);
        // }
      } else {
        Toast.fail(response.data.msg);
      }

      return Promise.reject(response.data);
    }
    return response.data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default ajax;
