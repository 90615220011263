import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../login/LoginPage";
import HomePage from "../views/Home";


Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  // 登录页
  {
    path: "/",
    name: "login",
    meta: {},
    component: LoginPage,
  },
  // =主页
  {
    path: "/home",
    name: "HomePage",
    meta: {
      title: '个人中心'
    },
    component: HomePage,
  },
  // < !--筛查报告详情页面 -->
  {
    path: '/inspection_result',
    name: 'inspection_result',
    component: () => import("../views/inspection_result/index"),
    meta: {
      title: '筛查报告'
    },
  },
  // < !--进行中筛查报告详情页面 -->
  {
    path: '/underway_result',
    name: 'underway_result',
    component: () => import("../views/underway_result/index"),
    meta: {
      title: '筛查报告'
    },
  },
  // 筛查报告
  {
    path: '/screening_report',
    name: 'screening_report',
    component: () => import("../views/screening_report/index"),
    meta: {
      title: '筛查报告'
    },
  },
  // 筛查报告查询
  {
    path: '/screening_results_query',
    name: 'screening_results_query',
    component: () => import("../views/screening_results_query/index"),
    meta: {
      title: '筛查结果查询'
    },
  },
  // 咨询页面
  {
    path: '/consult',
    name: 'consult',
    component: () => import("../views/consult/index"),
    meta: {
      title: '咨询页面'
    },
  },
  {
    path: "/check-user",
    name: "check-user",
    component: () => import("../login/CheckUser"),
  },
  {
    path: "/check-user2",
    name: "check-user2",
    component: () => import("../login/CheckUser2"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
