export const setToken = (token) => {
  sessionStorage.setItem("token", token);
};

export const getToken = () => {
  return sessionStorage.getItem("token");
};

export const clearToken = () => {
  sessionStorage.removeItem("token");
};

export const setOpenId = (openId) => {
  sessionStorage.setItem("openId", openId);
};

export const getOpenId = () => {
  return sessionStorage.getItem("openId");
};
export const setwxAccount = (openId) => {
  sessionStorage.setItem("wxAccount", openId);
};

export const getwxAccount = () => {
  return sessionStorage.getItem("wxAccount");
};

export const clearOpenId = () => {
  sessionStorage.removeItem("openId");
};