<!-- 个人中心页面 -->
<template>
  <div class="homepage">
    <div class="page_title" v-wechat-title="$route.meta.title"></div>
    <div class="home_content">
      <div class="homePage_top">
        <div class="avatar">
          <img v-if="userInfo && userInfo.headimgurl" :src="userInfo.headimgurl" alt="" />
          <img v-else src="../../assets/image/avatar.png" alt="" />
        </div>
        <div class="userinfo" style="width:52%;">
          <div class="username">{{ userInfo.nickname || '微信用户' }}</div>
          <div class="userid">ID：{{ userInfo.id }}</div>
        </div>
        <div class="homePage_bottom">
          <van-button type="primary" @click="addStatus">添加学生</van-button>
        </div>
      </div>
      <div class="homePage_center">
        <!-- 学生信息卡 -->
        <studentInfoCardVue ref="studentInfo" :studentIdProps="studentId" @update:studentsList="updateStudentsList"
          @create:studentsList="createStudentsList" @update:addInfo="updateAddInfo" v-show="!showAddCard">
        </studentInfoCardVue>
        <!-- 添加信息卡 -->
        <addInfoCardVue ref="addInfoCard" v-show="showAddCard" @update:success="updateSuccess"></addInfoCardVue>
        <!-- 学生列表 -->
        <div class="student_list" v-if="studentsList && studentsList.length > 0">
          <transition-group name="fade" tag="div">
            <div class="box_top" v-for="item in studentsList" :key="item.studentId">
              <div class="avatar">
                <img src="../../assets/image/avatar.png" alt="">
              </div>
              <div>
                <div class="name">
                  <span>{{ item.studentName || '-' }} </span>
                  <span class="age" style="margin-left: 10px;"> {{ item.sex == "0" ? "男" : "女" }}
                    <span class="line"></span> {{ item.age + '岁' || '' }}</span>
                </div>
                <div class="age" v-if="item.schoolName || item.gradeName || item.className">
                  {{ item.schoolName || '' }} {{ item.gradeName || "" }} {{ item.className || "" }}
                </div>
                <div v-else style="color: #FF3333;">暂未绑定班级</div>
              </div>
              <div class="choose_button">
                <div class="buttonstyle" @click="chooseStatudent(item.studentId, item.studentEditionId, item)">
                  选择学生
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <TabBar :activeProp="0"></TabBar>
  </div>
</template>
<script >
import TabBar from "@/views/components/tabbar";
import studentInfoCardVue from "@/components/studentInfoCard.vue"
import addInfoCardVue from "@/components/addInfoCard.vue"
import { setOpenId, getOpenId, setwxAccount, getwxAccount } from "@/utils/token";
import axios from 'axios'; // 引入 axios 库
import { getSex, maskIdNumber } from "@/utils/tools";
import { getQueryStudent } from "@/api/api";
import LoginPageVue from '@/login/LoginPage.vue';
export default {
  components: {
    studentInfoCardVue,
    addInfoCardVue,
    TabBar
  },
  data() {
    return {
      openId: getOpenId(),
      studentsList: null,
      userInfo: {},
      studentId: null,
      showAddCard: true,
      wxAccount: getwxAccount(),
      schooleInfo: {}
    };
  },
  created() {
    let url = window.location.href
    // let url = "https://intervention-center-mobile.uaqq.com/home?screeningPlanId=73&semesterName=2023-2024%E5%B9%B4%E4%B8%8B%E5%AD%A6%E6%9C%9F&schoolEditionId=48255&schoolName=%E9%82%AF%E9%83%B8%E5%B8%82%E7%AC%AC%E4%BA%8C%E4%B8%AD%E5%AD%A6&schoolType=3&wxAccount=gyzx&openId=oec6q58zvmAjuCsBR2Fkljqv75e0"
    let queryString = url.split('?')[1]
    const params = new URLSearchParams(queryString);
    if (params.size > 0) {
      this.wxAccount = params.get('wxAccount') ? params.get('wxAccount') : this.wxAccount
      setwxAccount(this.wxAccount ? this.wxAccount : 'gyzx')
      this.openId = params.get('openId') ? params.get('openId') : this.openId;
      sessionStorage.setItem('openId', this.openId)
      this.screeningPlanId = params.get('screeningPlanId');
      this.schooleInfo = {
        // schoolEditionId: params.get('schoolEditionId'),
        schoolType: params.get('schoolType'),
        schoolId: params.get('schoolId'),
        idCard: params.get('idCard') == 'null' ? '' : params.get('idCard'),
        studentName: decodeURIComponent(params.get('studentName')) == 'null' ? '' : decodeURIComponent(params.get('studentName')),
        mobile: params.get('mobile') == 'null' ? '' : params.get('mobile'),
        schoolName: decodeURIComponent(params.get('schoolName')),
        // semesterName: decodeURIComponent(params.get('semesterName'))
      }
    }
    if (JSON.parse(sessionStorage.getItem('userInfo')) != null) {
      this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    } else {
      if (!this.openId) {
        this.$router.replace('/')
      }
      const response = axios.get('https://api.ayoh.com.cn/api/ayoh-wechat/wechat/detail', {
        params: {
          woaKey: this.wxAccount,
          openId: this.openId // 将 openid 作为参数传递给接口
        }
      });
      response.then(res => {
        if (res.data.code == 0) {
          sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
          this.userInfo = res.data.data
        }
      })
    }
  },
  mounted() {
    this.$refs.addInfoCard.getqueryCurrentSemesterFn()
    this.$refs.addInfoCard.schooleInfo = this.schooleInfo
    if (this.schooleInfo.schoolName == 'null') {
      this.schooleInfo.schoolName = ''
    }
    this.$refs.addInfoCard.userInfo = this.schooleInfo
  },
  methods: {
    addStatus() {
      this.showAddCard = true
      this.$refs.addInfoCard.idCard_disabled = false
      this.$refs.addInfoCard.disabled = false
      this.$refs.addInfoCard.disabledGradeClass = true
      this.$refs.addInfoCard.buttonText = '添加学生'
      this.$refs.addInfoCard.userInfo = {}
      this.studentsList = JSON.parse(sessionStorage.getItem('studentsList'))
      this.$refs.addInfoCard.getqueryCurrentSemesterFn()
    },
    // 选择学生按钮回调
    chooseStatudent(id, studentEditionId, item) {
      if (studentEditionId) {
        this.$refs.studentInfo.studentId = id
        // this.$refs.studentInfo.getQueryStudentByidFn(this.studentsList)
        this.$refs.studentInfo.getQueryStudentFn()
        this.$refs.studentInfo.lodingType = false
        this.showAddCard = false
      } else {
        this.$refs.studentInfo.studentId = id
        this.$refs.studentInfo.propstudentInfo = item
        this.$refs.studentInfo.studentInfo = item
        this.$refs.studentInfo.getQueryStudentFn()
        this.$refs.studentInfo.boundType = true
        this.$refs.studentInfo.lodingType = false
        this.showAddCard = false
      }
    },
    updateStudentsList(item) {
      if (item) {
        this.studentsList = item
        if (this.showAddCard) {
          this.studentsList = JSON.parse(sessionStorage.getItem('studentsList'))
        }
      } else {
        this.studentsList && this.studentsList.length > 0 ? this.showAddCard = false : this.showAddCard = true
        this.$refs.addInfoCard.disabled = false
        this.$refs.addInfoCard.idCard_disabled = false
        this.$refs.addInfoCard.getqueryCurrentSemesterFn()
      }
    },
    createStudentsList(list) {
      this.studentsList = list
    },
    updateAddInfo(obj) {
      this.showAddCard = true
      this.studentsList = JSON.parse(sessionStorage.getItem('studentsList'))
      this.$refs.addInfoCard.userInfo = obj.item
      this.$refs.addInfoCard.type = obj.type
      this.$refs.addInfoCard.studentEditionId = obj.studentEditionId
      this.$refs.addInfoCard.buttonText = '修改信息'
      this.$refs.addInfoCard.idCard_disabled = true
      this.$refs.addInfoCard.staryearFn()
    },
    updateSuccess(id, type) {
      this.$refs.studentInfo.getQueryStudentFn()
      this.$refs.studentInfo.studentId = id
      console.log('id***=>', id);
      this.showAddCard = false
    }
  },
};
</script>
<style lang="less" scoped>
.homepage {
  min-width: 100vw;
  min-height: 100vh;
  background: #f7f7f7;
}

.home_content {
  padding-bottom: 40px;
}

.homePage_top {
  height: 72px;
  background-image: url("../../assets/image/owerbag.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 17px 15px;

  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .username {
    font-family: PingFangSC-Semibold;
    font-size: 17px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .userid {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #ffffff;
    font-weight: 400;
  }
}

.homePage_center {
  background: #f7f7f7;
  height: 100%;
  padding: 15px;
  margin-top: -23px;
  border-radius: 15px 15px 0px 0px;
}


.homePage_bottom {

  .van-button--primary {
    width: 80px;
    height: 34px;
    background: #FFF;
    border-radius: 5px;
    color: #333;
    padding: 0px !important;
    border: none;
  }
}

.student_list {
  margin-top: 10px;

  .box_top {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px;
    position: relative;
  }

  .choose_button {
    position: absolute;
    right: 5px;
    top: 20px;

    .buttonstyle {
      background: #0CC777;
      border-radius: 14px;
      width: 72px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 400;

    }
  }
}

.box_top {
  display: flex;
  align-items: center;
  margin-bottom: 10.5px;


  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 12px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .name {
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  .age {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #666666;
    font-weight: 400;
    display: inline-block;
  }

  .line {
    display: inline-block;
    background: #D8D8D8;
    width: 1px;
    height: 10px;
    margin: 0px 5px;
  }
}
</style>