<template>
    <div class="tabbar">
        <van-tabbar v-model="active" active-color="#0CC777" inactive-color="#999999 " @change="tabbarFn">
            <van-tabbar-item>个人中心</van-tabbar-item>
            <van-tabbar-item>筛查报告</van-tabbar-item>
        </van-tabbar>
        <!-- <div class="tabbar_bottom"></div> -->
    </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
export default {
    props: ["activeProp"],
    data() {
        return {
            active: null,
        };
    },

    mounted() {
        this.active = this.activeProp
    },

    methods: {
        tabbarFn(e) {
            if (e == '0') {
                this.$router.push('/home')
            }
            if (e == '1') {
                let list = JSON.parse(sessionStorage.getItem('studentsList'))
                if (list && list.length <= 0) {
                    Toast('暂未绑定学生，请先绑定学生！');
                    return
                }
                this.$router.push('/screening_report')
            }
        }
    },
};
</script>
<style lang="less" scoped>
.van-tabbar--fixed {
    position: fixed;
    bottom: 0px;
    left: 0;
}

.van-tabbar-item {
    height: 50px;
}

.tabbar_bottom {
    width: 100%;
    height: 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFF;

}
</style>