// 学校类型的对照

export const schoolTypeDict = [
    {key: 1, value: "幼儿园"},
    {key: 2, value: "小学"},
    {key: 3, value: "初中"},
    {key: 4, value: "高中"},
    {key: 5, value: "小初"},
    {key: 6, value: "初高"},
    {key: 7, value: "职高"},
    {key: 8, value: "小初高"},
    {key: 0, value: "未知"},
]