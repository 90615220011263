import axios from "../utils/axios";

// 查询登录用户信息
export const getInfo = (params) => {
  return axios({
    url: '/getInfo',
    method: 'get',
    params
  });
};

export const getDeposit = (data) => {
  return axios({
    url: `/deposit/patient-we-chat/patient-query-self-deposit/${data.openId}/${data.hospitalDeptCode}`,
    method: "post",
    data: data,
  });
};

// 查询保证金明细
export const getDepositDetail = (params) => {
  return axios({
    url: `/deposit/deposit-detail/${params.id}/${params.openId}`,
    method: 'post',
  });
};

export const wxLogin = (params) => {
  return axios({
    url: "/api/ayoh-wechat/wechat/wxlogin",
    method: "get",
    params,
  });
};
// 查询科室
export const getDepositDeptType = (data) => {
  return axios({
    url: `/deposit/patient-we-chat/patient-query-hospital-dept-type/${data.openId}`,
    method: "get",
    data,
  });
};
// 查询学生版本信息
export const getQueryStudentByid = (data) => {
  return axios({
    url: `/wechat/query-student-byid/${data}`,
    method: "get",
    data,
  });
};
// 查询绑定学生信息
export const getQueryStudent = (data) => {
  return axios({
    url: `/wechat/query-student`,
    method: "post",
    data: data,
  });
};
// 查询学校
export const getQuerySchool = (data) => {
  return axios({
    url: `/wechat/query-school`,
    method: "get",
    params: data,
  });
};
// 新增学生
export const getAddStudent = (data) => {
  return axios({
    url: `/wechat/add-student`,
    method: "post",
    data: data,
  });
};
// 修改学生
export const getUpdateStudent = (data) => {
  return axios({
    url: `/wechat/update-student`,
    method: "post",
    data: data,
  });
};
// 查询学期
export const getqueryCurrentSemester = (data) => {
  return axios({
    url: `/wechat/query-current-semester`,
    method: "get",
  });
};
// 接触绑定
export const deleteUserRelation = (data) => {
  return axios({
    url: `/wechat/delete-user-relation`,
    method: "post",
    data
  });
};
// 筛查报告
export const getScreeningPlan = (data) => {
  return axios({
    url: `/wechat/screening-plan`,
    method: "post",
    data
  });
};
// 筛查报告详情
export const getInspectionDetails = (data) => {
  return axios({
    url: `/wechat/inspection-details`,
    method: "post",
    data
  });
};
// 筛查进度
export const getScreeningProcess = (data) => {
  return axios({
    url: `/wechat/screening-process`,
    method: "post",
    data
  });
};
// 身份证号查询学生Id
export const getQueryRelation = (data) => {
  return axios({
    url: `/wechat/query-relation`,
    method: "post",
    data
  });
};
// 档案查询
export const getQueryScreeningReport = (data) => {
  return axios({
    url: `/wechat/query-screening-report`,
    method: "post",
    data
  });
};
// 口腔筛查报告
export const getQueryScreeningOralReport = (data) => {
  return axios({
    url: `/wechat/query-screening-oral-report`,
    method: "post",
    data
  });
};
// 家长咨询
export const sendQueryMsg = (data) => {
  return axios({
    url: `/wechat/send-query-msg`,
    method: "post",
    data
  });
};

// 获取学校年级信息
export const getGrade = (data) => {
  return axios({
    url: '/wechat/get-grade',
    method: "post",
    data
  });
};

 // 获取学校班级信息
export const getClass = (data) => {
  return axios({
    url: '/wechat/get-class',
    method: "post",
    data
  });
};