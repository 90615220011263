<script>
import { wxLogin } from '@/api/api'
export default {
  mounted() {
    let redirect = window.location.origin + "/check-user" + window.location.search;
    window.location.href = "https://api.ayoh.com.cn/api/ayoh-wechat/wechat/wxlogin?woaKey=gyzx&url=" + encodeURIComponent(redirect);
  },
};
</script>

<template>
  <div></div>
</template>

<style scoped lang="less"></style>
