// wechat2.0
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import "./router/navigation-guards";

import store from "./store";
import "./assets/styles/index.css";
import "amfe-flexible";
import Vant from "vant";
import { Notify } from 'vant';
import { getParams } from './utils/index'
import VueWechatTitle from 'vue-wechat-title'
import "vant/lib/index.css";
import eruda from 'eruda';

if (process.env.NODE_ENV === "development" || getParams('ayoh-debug-zpf')) {
  eruda.init();
}
Vue.use(VueWechatTitle)
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(Notify);
Vue.filter('formatMoney', (num) => {
  var res = num.toString().replace(/\d+/, function (n) { // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + "'";
    });
  })
  return res;
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
