import store from "@/store";
import { getInfo } from "@/api/api";
import {clearSession} from "@/utils/tools";

const user = {
  state: {
    id: "",
    username: "",
    userinfo: "",
  },

  mutations: {
    SET_ID: (state, id) => {
      state.id = id;
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
    },
    SET_INFO: (state, userinfo) => {
      state.userinfo = userinfo;
    },
  },

  actions: {
    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((result) => {
            const respData = result;
            commit("SET_ID", respData.user.userId);
            commit("SET_USERNAME", respData.user.nickName);
            commit("SET_INFO", respData.user);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_ID", "");
        commit("SET_USERNAME", "");
        clearSession()
        resolve();
      });
    },
  },
};

export default user;
