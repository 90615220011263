<!-- 学生信息页面 -->
<template>
    <div class="student_Information">
        <div v-wechat-title="$route.meta.title"></div>
        <div class="Information_content" v-show="showPage">
            <div :class="!show ? 'Information_box' : ''" v-show="lodingType">
                <div id="capture" :class="show ? 'content_box' : ''">
                    <div :class="show ? 'img_box' : ''">
                        <div class="box_top">
                            <div class="avatar">
                                <img src="../assets/image/avatar.png" alt="">
                            </div>
                            <div v-if="studentInfo">
                                <div style="display: flex;align-items: center;">
                                    <div class="name" style="margin-right: 10px;">{{ studentInfo.studentName || '-' }}</div>
                                    <div class="age">
                                        {{ studentInfo.sex == '0' ? '男' : studentInfo.sex == '1' ? '女' : '-' }}
                                        <span class="line"></span>
                                        {{ studentInfo.age || '-' }}岁
                                        <span class="line"></span>
                                        {{ studentInfo.mobile || '-' }}
                                    </div>
                                </div>
                                <div class="age"
                                    style="margin-top: 06px;display: flex;justify-content: space-between;align-items: center; ">
                                    <span>
                                        身份证：<span class="info" v-if="!openType">{{ maskIdNumberFn(studentInfo.idCard) || '-'
                                        }}</span>
                                        <span class="info" v-else>{{ studentInfo.idCard || '-' }}</span>
                                    </span>
                                    <div class="icon" @click="openEye">
                                        <van-icon v-if="openType" name="eye-o" />
                                        <van-icon v-else name="closed-eye" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box_center">
                            <div class="display">
                                <div class="name">学期：</div>
                                <div class="info" v-if="studentInfo.semesterName">{{ studentInfo.semesterName || '-' }}
                                </div>
                            </div>
                            <div class="display">
                                <div class="name">学校：</div>
                                <div class="info">{{ studentInfo.schoolName || '' }} {{ studentInfo.gradeName || '' }} {{
                                    studentInfo.className || ''
                                }}
                                </div>
                            </div>
                        </div>
                        <div class="QR_code" v-if="showBtn">
                            <div ref="qrcode" class="qrcode" :class="{ flipped: isFlipped }"></div>
                        </div>
                        <!-- <div class="tabQrcode" v-if="showBtn" @click="toggleQRCode">点击切换二维码</div> -->
                        <div class="no_code" v-if="!showBtn" @click="boundType = true">
                            <div class="noqrcode">
                                <div class="noqrcode_text">暂无二维码</div>
                                <div style="padding: 0 13px;">点击此处“绑定班级”后，才可以生成二维码</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_bottom" v-if="showBtn">
                    <van-button plain type="primary" @click="updata(studentInfo)">修改信息</van-button>
                    <van-button type="primary" @click="savePhotoAlbum">保存到相册</van-button>
                </div>
                <div class="call_off">
                    <span @click="deleteUserRelationFn(studentInfo.studentId)">取消绑定</span>
                </div>
            </div>
            <van-loading type="spinner" v-show="!lodingType" size="50" />
            <!-- 长按保存 -->
            <van-popup v-model="show">
                <div class="contentImg">
                    <img :src="imgUrl" width="230px" />
                    <div>长按上方图片保存</div>
                </div>
            </van-popup>
            <!--绑定学校弹框  -->
            <van-popup v-model="boundType" round :style="{ height: '60%', width: '85%' }" :close-on-click-overlay="false"
                closeable @close="boundTypeClose(propstudentInfo)">
                <popupVue :studentInfo="propstudentInfo" :siteId="siteId" @update:boundType="handleUpdateMessage">
                </popupVue>
            </van-popup>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { getQueryStudent, deleteUserRelation } from "@/api/api";
import { maskIdNumber, getSex } from "@/utils/tools";
import { getOpenId, getwxAccount } from "@/utils/token";
import QRCode from 'qrcodejs2'
import { Toast, Dialog, Loading } from 'vant';
import popupVue from "@/views/student_Information/components/popup.vue";
import { set } from 'vue';
export default {
    components: {
        popupVue
    },
    data() {
        return {
            show: false,
            openId: getOpenId(),
            wxAccount: getwxAccount(),
            studentInfo: {},
            propstudentInfo: {},
            openType: false,
            qrcodeInstance: null,
            boundType: false,
            showPage: false,
            lodingType: true,
            studentId: '',
            siteId: null,
            imgUrl: '',
            showBtn: true,
            isFlipped: false,
            currentQRCodeType: 'id'
        };
    },
    watch: {
        studentId(newValue) {
            if (newValue) {
                this.studentId = newValue
                console.log("neew this.studentId", newValue);
            }
        },
        studentInfo(newValue, oldValue) {
        },
    },
    created() {
        let url = window.location.href
        let queryString = url.split('?')[1]
        const params = new URLSearchParams(queryString);
        if (params.size > 0) {
            this.siteId = params.get('siteId') ? params.get('siteId') : null
        }
        if (!this.boundType) {
            this.getQueryStudentFn()
        }
    },
    mounted() {
    },
    methods: {
        // 保存相册
        savePhotoAlbum() {
            this.show = true
            setTimeout(() => {
                html2canvas(document.querySelector("#capture")).then(canvas => {
                    this.imgUrl = canvas.toDataURL("png"); // 获取生成的图片的url
                })
            }, 0)
        },
        boundTypeClose(e) {
            this.boundType = false
            // this.getQueryStudentFn()
        },
        handleUpdateMessage(newMessage) {
            this.boundType = newMessage.type;
            this.studentId = newMessage.studentId
            this.getQueryStudentFn()
        },
        // 修改信息接口
        updata(item) {
            let obj = {
                item: item,
                type: '修改',
                studentEditionId: item.studentEditionId
            }
            this.$emit('update:addInfo', obj);
        },
        generateQRCode(text) {
            console.log('text', text);
            // 销毁旧的 QRCode 实例
            if (this.qrcodeInstance !== null) {
                this.qrcodeInstance.clear();
                this.qrcodeInstance = null;
                if (this.$refs.qrcode) {
                    this.$refs.qrcode.innerHTML = '' // 清空之前的二维码内容
                }
            }
            // 创建新的 QRCode 实例
            setTimeout(() => {
                this.qrcodeInstance = new QRCode(this.$refs.qrcode, {
                    text: text,
                    width: 140,
                    height: 140,
                });
            }, 0)

        },
        // toggleQRCode() {
        //     // 切换二维码类型
        //     this.isFlipped = !this.isFlipped;
        //     setTimeout(() => {
        //         if (this.currentQRCodeType === 'idNumber') {
        //             this.currentQRCodeType = 'id';
        //             this.generateQRCode(this.studentInfo.qrCode);
        //         } else {
        //             this.currentQRCodeType = 'idNumber';
        //             this.generateQRCode(this.studentInfo.idCard);
        //         }
        //     }, 800);


        // },
        // 身份证号加密
        maskIdNumberFn(id) {
            return maskIdNumber(id);
        },
        // 获取年龄
        getGenderText(sex) {
            return getSex(sex);
        },
        // 查询绑定学生信息
        getQueryStudentFn(type) {
            let body = {
                "openId": this.openId,
                wxAccount: this.wxAccount,
            };
            getQueryStudent(body).then((res) => {
                if (res.data.length > 0) {
                    if (!this.studentId && type != 'add') {
                        this.studentId = res.data[0].studentId
                    }
                    this.getQueryStudentByidFn(res.data)
                    sessionStorage.setItem('studentsList', JSON.stringify(res.data))
                    this.$emit('create:studentsList', res.data);
                    this.listChange(res.data)
                    this.showPage = true
                    setTimeout(() => {
                        this.lodingType = true
                    }, 300);
                } else {
                    // 去添加
                    this.showPage = false
                    this.$emit('update:studentsList', false);
                    sessionStorage.setItem('studentsList', JSON.stringify(res.data))
                }

            }).catch(err => {
                console.log("err", err);
            })
        },
        listChange(item) {
            let arr = []
            item.map(i => {
                if (i.studentId != this.studentId) {
                    arr.push(i)
                }
            })
            this.$emit('update:studentsList', arr);
        },
        // 根据ID查询学生信息
        getQueryStudentByidFn(list) {
            let arr
            let id = this.studentId
            arr = list.filter(i => {
                if (i.studentId == id) {
                    return i
                }
            })
            this.studentInfo = arr[0]
            if (this.studentInfo.qrCode) {
                this.showBtn = true
                if (this.studentInfo.idCard) {
                    this.generateQRCode(this.studentInfo.idCard)
                } else {
                    this.generateQRCode(this.studentInfo.qrCode)
                }
            } else {
                this.showBtn = false
            }
        },
        // 小眼睛
        openEye() {
            if (this.openType) {
                this.openType = false
            } else {
                this.openType = true
            }

        },
        // 取消绑定
        deleteUserRelationFn(studentId) {
            Dialog.confirm({
                message: '请确认是否解绑该学生',
            })
                .then(() => {
                    console.log('studentId', studentId);
                    let obj = {
                        "openId": this.openId,
                        wxAccount: this.wxAccount,
                        "studentId": studentId
                    }
                    deleteUserRelation(obj).then(res => {
                        if (res.code == 200) {
                            Toast('解绑成功')
                            this.studentId = ''
                            this.getQueryStudentFn()
                        }
                    }).catch(err => {
                        console.log("err", err);
                    })
                })
                .catch(() => {
                    // on cancel
                });
        }
    }
}
</script>

<style lang="less" scoped>
.content_box {
    width: 100%;
    background: #f7f7f7;
    padding: 10px;

    .img_box {
        border-radius: 10px;
        background: #FFF;
        padding: 15px;
    }
}

.contentImg {
    height: 270px;
    width: 250px;
    text-align: center;
    background: #f7f7f7;

    .imgSave {
        border-radius: 10px;
        background: #FFF;
        padding: 15px;
    }
}

.qrcode {
    background: #fff;
    height: 150px;
    width: 150px;
    border: 0.5px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 1.9s;
    transform-style: preserve-3d;
    //     width: 130px;
    //     height: 130px;
    //     margin: 10px;
    // }
}

.noqrcode {
    background: #fff;
    height: 150px;
    width: 150px;
    border: 0.5px solid lightgray;

    .noqrcode_text {
        width: 100%;
        text-align: center;
        margin: 20px 0;
    }
}


.qrcode.flipped {
    transform: rotateY(180deg);
}

.student_Information {
    background: #f7f7f7;
}

.Information_content {
    background: #f7f7f7;
}

/deep/.van-loading {
    position: relative;
    left: 45%;
    top: 50%;
    color: #c8c9cc;
    font-size: 0;
    vertical-align: middle;
    height: 280px;
    margin-top: 30%;
}

.Information_box {
    background: #FFF;
    padding: 15px;
}

.call_off {
    text-align: center;
    padding: 10px 0;

    span {
        font-size: 14px;
    }
}

.box_top {
    display: flex;
    align-items: center;
    margin-bottom: 10.5px;


    .avatar {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 12px;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .name {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #333333;
        font-weight: 600;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
    }

    .age {
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #666666;
        font-weight: 400;
        display: inline-block;
    }

    .line {
        display: inline-block;
        background: #D8D8D8;
        width: 1px;
        height: 10px;
        margin: 0px 5px;
    }
}

.van-empty {
    padding: 0;
    margin-bottom: 30px;


}

.box_center {
    background: #F7F7F7;
    border-radius: 5px;
    padding: 10px 12px;
    margin-bottom: 16px;
    position: relative;

    .display {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .name {
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #666666;
        font-weight: 400;
    }

    .info {
        font-family: PingFangSC-Regular;
        font-size: 13px;
        color: #333333;
        font-weight: 400;
    }

}

.QR_code {
    background: #D8D8D8;
    width: 140px;
    height: 140px;
    margin: 10px auto 20px auto;

    img {
        width: 100%;
        height: 100%;
    }
}

.no_code {
    background: #D8D8D8;
    width: 140px;
    height: 140px;
    margin: 10px auto 30px auto;
}


.tabQrcode {
    text-align: center;
    margin-bottom: 10px;
    margin-left: 10px;
}

.box_bottom {
    display: flex;
    justify-content: space-between;

    .van-button {
        width: 48%;
    }

    .van-button--primary {
        // background: #0CC777;
        border-radius: 5px;
    }

    .van-button--default {
        border: none;
    }
}
</style>


