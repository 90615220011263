<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  destroyed() {
    localStorage.removeItem('screeningPlanId', this.screeningPlanId)
    localStorage.removeItem('semesterName', this.semesterName)
    localStorage.removeItem('wxAccount', this.wxAccount)
    localStorage.removeItem('openId', this.openId)
  },
}


</script>